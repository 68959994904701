
// **********
// JS Global variable
// **********
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

// **********
// @import libraries
// **********
require('@fortawesome/fontawesome-free/js/all.min.js');
require('bootstrap-cookie-alert/cookiealert');
require('bootstrap-cookie-alert/cookiealert.css');


// **********
// @import bootstrap components
// **********
require('bootstrap');



// **********
// @import form
// **********


// **********
// @import modal
// **********



// **********
// @import teaser
// **********



// **********
// @import detail
// **********



// **********
// @import components
// **********
require('../components/header');
require('../components/footer');
require('../components/cookie');



// **********
// custom scripts
// **********
$(window).scroll(function() {
    if ($(this).scrollTop() >= 50) {
        $('#return-to-top').fadeIn(300);
    } else {
        $('#return-to-top').fadeOut(300);
    }
});
$('#return-to-top').click(function() {
    $('body,html').animate({
        scrollTop : 0
    }, 1000);
});


// **********
// @import page style .scss
// **********
require('./layout.scss');